.dragContainer {
  padding-top: 6px;
}

.dragHandler {
  cursor: grab;
}

/* .dragElement {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px 10px 16px;
  margin-bottom: 14px;
  border: 1px dashed lightblue;
  border-radius: 8px;
} */

.dragElement {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px 5px 16px;
  margin-bottom: 8px;
  background-color: white;
  border: 1px solid transparent;
}
.dragElement:hover {
  border: 1px solid lightblue;
}