#brand-logo {
  width: auto;
  height: 30px;
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.mobile-logout {
  display: none;
}
.mobile-hamburger {
  display: none;
}
@media screen and (max-width: 892px) {
  .desktop-logout {
    display: none;
  }
  .mobile-logout {
    display: block;
  }
  .mobile-hamburger {
    display: block;
    margin-left: 12px;
  }
}
