body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  width: 100%;
}

.full-centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
h3 {
  margin: 8px 0;
}
p {
  font-size: 14px;
  margin: 0;
}
*{
  box-sizing: border-box;
}

h2{
  padding: 0;
  margin: 0;
  margin: 6px 0;
  font-size: 1.3rem;
}
